import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import NumberFormat from "react-number-format";
import AutoSelectOnTab from './AutoSelectOnTab';

function Editable({
    type = 'text',
    defaultValue = '',
    onChange = null,
    focusField = false,
    options = [],
    size = 80,
    maxLength = 100,
    onKeyDown = null,
    autoFocus = false,
    tabIndex = 1,
    preFix = '',
    postFix = '',
    placeholder = "",
    onBlur = null
}) {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleInputChange = (e) => {
        setValue(e.target.value);
        if (onChange) onChange(e);
    };

    const handleSelectChange = (newValue) => {
        setValue(newValue)
        if (onChange) onChange(newValue);
    };

    const handleNumberFormatChange = (values) => {
        setValue(values.value);
        if (onChange) onChange(values);
    };

    return (
        <>
            {type === 'text' && focusField ?
                <input
                    key={focusField}
                    tabIndex={tabIndex}
                    style={{ width: size }}
                    className='d-inline'
                    autoFocus={autoFocus}
                    value={value}
                    onChange={handleInputChange}
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    onBlur={onBlur}
                />
                : type === 'select' && focusField ?
                    <AutoSelectOnTab
                        key={focusField}
                        tabIndex={tabIndex}
                        placeholder={placeholder}
                        value={value?? undefined}
                        options={options}
                        autoFocus={autoFocus}
                        onChange={handleSelectChange}
                        handleTabPress={handleSelectChange}
                        style={{ width: size }}
                    />
                    : type === 'value' && focusField ?
                        <NumberFormat
                            style={{ width: size }}
                            tabIndex={tabIndex}
                            thousandSeparator={true}
                            prefix={"$ "}
                            variant="outlined"
                            value={value}
                            allowEmptyFormatting
                            autoFocus={autoFocus}
                            onValueChange={handleNumberFormatChange}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder}
                            onBlur={onBlur}
                        />
                        :
                        <span tabIndex={tabIndex} className='value'>{defaultValue ?`${preFix} ${defaultValue}${postFix}`: '-'}</span>
            }
        </>
    )
}

export default Editable