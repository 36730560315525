import React from 'react'
import { connect } from 'react-redux'
import { resetOpenJob, setIsNewJob } from '../store/job/actions'

export const NewJobMenuLink = (props) => {
    return (
        <div className="job-item" onClick={(e) => {
            if (Object?.keys(props?.newJob?.data)?.length > 0)
                props.resetOpenJob()
            props.setIsNewJob(true)
        }}>
            {props.children}
        </div>
    )
}

const mapStateToProps = (state) => ({
    newJob: state.JobReducer.newJob
})

const mapDispatchToProps = (dispatch) => {
    return {
        setIsNewJob: (isNew) => dispatch(setIsNewJob(isNew)),
        resetOpenJob: () => dispatch(resetOpenJob())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewJobMenuLink)